import { call, put } from "redux-saga/effects";
import { updateSingle, setError } from "../../slices/single";
import requestCreateSingle from "../request/createsingle";
import history from "src/routes/history";
import errors from "src/redux/saga/errors";
import { getSingle, purgeSingleState } from 'src/redux/slices/single';

export function* handleCreateSingle(action) {
    try {
        const { payload } = action;
        const { id } = payload;
        const response = yield call(requestCreateSingle, payload);
        const { data } = response;        
        if(id === 0){
            const arrayLocation = window.location.pathname.split('/');
            const folder = arrayLocation[arrayLocation.length-3];

            history.push(data[0].value);
            yield put(purgeSingleState());
            yield put(getSingle({title: folder, id: data[0].value}));
        } else {
            yield put(updateSingle(data));
        }
    } catch (error){
        // yield put(setError());
        console.log(error);
        errors(error);
    }
}