import { createSlice } from '@reduxjs/toolkit';
import { loginUser, fetchUserBytoken } from "../saga/request/login"
import { logoutUser } from "../saga/request/logout"

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    username: localStorage.getItem('username') || null,
    email: localStorage.getItem('email') || null,
    token: localStorage.getItem('token') || null,
    role: localStorage.getItem('role') || null,
    avatar: localStorage.getItem('avatar') || null,
    name: localStorage.getItem('name') || null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: ''
  },
  reducers: {
    clearState: (state, action) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;

        return state;
    },
    clearLog(state) {
      state.email = null;
      state.username = null;
      state.token = null;
      state.role = null;
      state.isFetching = false;
      state.isSuccess = true;
      return state;      
    }
  },
  extraReducers: {    
      [logoutUser.fulfilled]: (state, { payload }) => {
        state.email = null;
        state.username = null;
        state.token = null;
        state.role = null;
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      },
      [logoutUser.rejected]: (state, { payload }) => {
        console.log('payload', payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      },
      [logoutUser.pending]: (state) => {
        state.isFetching = true;
      },
      [loginUser.fulfilled]: (state, { payload }) => {
        state.email = payload.email;
        state.username = payload.username;
        state.token = payload.token;
        state.role = payload.role;
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      },
      [loginUser.rejected]: (state, { payload }) => {
        console.log('payload', payload);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      },
      [loginUser.pending]: (state) => {
        state.isFetching = true;
      },      
      [fetchUserBytoken.pending]: (state) => {
        state.isFetching = true;
      },
      [fetchUserBytoken.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
  
        state.email = payload.email;
        state.username = payload.name;
      },
      [fetchUserBytoken.rejected]: (state) => {
        console.log('fetchUserBytoken');
        state.isFetching = false;
        state.isError = true;
      },      
  }
})

export const userSelector = (state) => state.user;

export const { clearState, clearLog } = loginSlice.actions

export default loginSlice.reducer