import axios from "axios";

export default function handleCreateBlock(endpoint){

    const { id, entity, name, blocks } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };
    const locale = localStorage.getItem('i18nextLng');

    const data = {
        blocks: blocks
    };


    ///{lang}/{entity}/{id-entity}/slider/{name-slider}/{id-slide}
    
    return axios.put(process.env.REACT_APP_API_URL+'/'+locale+'/'+entity+'/visual/'+name+'/'+id, data,config)

}