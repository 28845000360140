import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import loginSlice from "./slices/login";
import listingSlice from "./slices/listing";
import singlegSlice from "./slices/single";
import langSlice from "./slices/langs";
import propertySlice from "./slices/postlist";
import propertyvalSlice from "./slices/postlistvalue";
import slider from "./slices/slider";
import visual from "./slices/visual";
import menu from "./slices/menu";
import filemanager from "./slices/filemanager";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['filemanager', 'settings', 'listing', 'single', 'property', 'propertyval', 'slider', 'visual', 'menu']
};

const rootReducer = combineReducers({
  filemanager: filemanager,
  settings: settingsReducer,
  listing: listingSlice,
  single: singlegSlice,
  property: propertySlice,
  propertyval: propertyvalSlice,
  slider: slider,
  visual: visual,
  login: loginSlice,
  lang: langSlice,
  menu: menu  
});

export { rootPersistConfig, rootReducer };
