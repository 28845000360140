import axios from "axios";

export default function requestCreateSingle(endpoint){

    const { pagename, id, data, parentid } = endpoint;

    let lang = localStorage.getItem('i18nextLng');

    const config = {
        params: {
            'parentid': parentid
        },        
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.put(process.env.REACT_APP_API_URL+"/"+lang+"/"+pagename+"/"+id, JSON.stringify(data), config);
}