// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_HOME = {
  root: '/',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-setting/',
  home: path(ROOTS.home, '/home/home')
};

export const PATH_AUTH = {
  root: ROOTS.auth,
  login: path(ROOTS.auth, '/login')
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    analytics: path(ROOTS.app, '/dashboard/analytics'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce')
  },
  setting: {

  },
  warehouse: {
    root: path(ROOTS.app, '/warehouse'),
    import: path(ROOTS.app, '/warehouse/import'),
    search: path(ROOTS.app, '/warehouse/search')
  },  
  app: {
    root: path(ROOTS.app, '/setting'),
    tracking: path(ROOTS.app, '/tracking'),
    filemanager: path(ROOTS.app, '/filemanager'),
    importstorelocator: path(ROOTS.app, '/importexport/storelocator'),
    listing: path(ROOTS.app, '/listing/:list'),
  },
  single: {
    post: path(ROOTS.app, '/post/:entity/single/:id'),
  }
};
