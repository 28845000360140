import axios from "axios";

export default function handleCreateBlock(endpoint){

    const { id, entity, entityid, name, data } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };
    const locale = localStorage.getItem('i18nextLng');

    const blocks = {
        blocks: data
    };
    
    return axios.put(process.env.REACT_APP_API_URL+'/'+locale+'/'+entity+'/'+entityid+'/visual/'+name+'/'+id, blocks, config);

}